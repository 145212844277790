import React from 'react';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import {BannerAds,GridAds } from '../components/AdsComponent';

const DetailPhotoFiction = ({ data }) => {
  const { title, litpic, typename, tag, body, series } = data;

  return (
    <div className="detail-photo-fiction-container">
      <Meta title={`${title || '精彩内容'}-3B`} description={`欣赏精彩内容-${title || '精彩内容'}`} keywords={`3B,${title || '精彩内容'},${tag || ''},${series || ''}`} />
      <BannerAds />
      <GridAds/>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">首页</Link> &gt; <Link to={`/${typename}`}>{typename}</Link><a className="float-right" href="#test">能赚钱的电子游戏</a>
      </div>
      {title ? (
      <h1 className="w-full text-center p-6 my-4 bg-tb">{title}</h1>
      ) : (
      <h1 className="w-full text-center p-6 my-4 bg-tb">暂无标题</h1>
      )}
      {litpic ? ("") : ("")}
      {body ? (
      <div className="w-full text-xl leading-loose" dangerouslySetInnerHTML={{ __html: body }} />
      ) : (
      <div className="w-full">本内容已下架</div>
      )}
      
      <div className="tags">
        {tag && tag.length > 0 ? (
           <>
           <div className="px-2 my-4">相关标签：</div>
           <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
             {tag.map((t, index) => (
               <Link key={index} to={`/tags/${t}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{t}</Link>
             ))}
           </div>
           </>
        ) : ("")}
      </div>
      {series ? (<>
      <div className="px-2 my-4">所属专辑：</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
        <Link to={`/series/${series}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{series}</Link>
      </div>
      </>) : ("")}
    </div>
  );
};

export default DetailPhotoFiction;
