import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import { LazyBannerImage, placeholder, errorPlaceholder } from './LazyBannerImage';
import { LazyImage } from './LazyImage';
import ErrorRetry, { LoadingText, ErrorRetryOnlyBtn } from './ErrorRetry';

const useAds = () => {
  
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
  
    const fetchAds = async () => {
      setLoading(true); // 开始加载
      setError(false); // 重置错误状态
  
      try {
        await new Promise(resolve => setTimeout(resolve, 0));
        
        const response = await axios.get('/c.json');
        const data = response.data.ads;
  
        if (data) {
          const adsArray = Object.keys(data).map(key => ({ ...data[key], id: key }));
          setAds(adsArray);
        } else {
          console.error('Failed to fetch ads:', response.data);
          setError(true);
        }
      } catch (error) {
        console.error('Error fetching ads:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchAds();
    }, []);
  
    return { ads, loading, error, fetchAds };
};

export const BannerAds = () => {
  
    const { ads, loading, error, fetchAds } = useAds();
  
    if (loading) {
      return <LoadingText />;
    }
  
    if (error) {
      console.error("Banner Link Load Error...");
      return <ErrorRetry title={'Banner'} onClick={fetchAds} />;
    }
  
    let bannerAds = ads.filter(ad => ad.type === 'banner');
    
    if (bannerAds.length > 0) {
      const randomIndex = Math.floor(Math.random() * bannerAds.length);
      bannerAds = [bannerAds[randomIndex]];
    }
      // 解析密文和 IV
      const cipherText = CryptoJS.enc.Base64.parse(bannerAds[0].title[0]); 
      const iv = CryptoJS.enc.Base64.parse(bannerAds[0].title[1]); 
      // 使用固定密sz钥进行解密
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
      // 解密操作
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
      let plainText = decrypted.toString(CryptoJS.enc.Utf8);
      plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
      let textTitle = plainText;
      

    return (
      <div className="banner min-h-[100px] w-full flex flex-wrap mb-4">
        {bannerAds.map(banner => (
          <Link
            className="w-full sm:w-1/2"
            key={banner.id}
            to={`/b/${banner.id}`}
            state={{
              src: banner.src,
              title: textTitle,
              href: banner.href,
              owner:banner.owner
            }}
          >
            <LazyBannerImage
              src={banner.src}
              alt={textTitle}
              placeholder={placeholder(398, 100)}
              errorPlaceholder={<div className="px-4 py-8 break-words border-2">{textTitle}</div>}
            />
          </Link>
        ))}
      </div>
    );
};

export const TextAds = ({ id }) => {
    const { ads, loading, error } = useAds();
  
    if (loading) {
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">加载中...</div>;
    }
  
    if (error) {
      console.error("Text Link Load Error...");
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">加载 Text.Link 失败，无妨，不影响看片</div>;
    }
  
    const textAds = ads.filter(ad => ad.type === 'text');
    let adToDisplay;

    if (id) {
        adToDisplay = textAds.find(ad => ad.id === id);
    } else {
        adToDisplay = textAds[Math.floor(Math.random() * textAds.length)];
    }

    if (!adToDisplay) {
      return <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">此处暂无内容，无妨，不影响看片</div>;
    }

     // 解析密文和 IV
     const cipherText = CryptoJS.enc.Base64.parse(adToDisplay.title[0]); 
     const iv = CryptoJS.enc.Base64.parse(adToDisplay.title[1]); 
     // 使用固定密sz钥进行解密
     const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
     // 解密操作
     const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
     let plainText = decrypted.toString(CryptoJS.enc.Utf8);
     plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
     let textTitle = plainText;
  
    return (
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">
        <Link to={`/b/${adToDisplay.id}`}
        state={{
          src: adToDisplay.src,
          title: textTitle,
          href: adToDisplay.href,
          owner:adToDisplay.owner
        }}
        >{textTitle}</Link>
      </div>
    );
};

export const TagAds = () => {
 
    const { ads, loading, error } = useAds();
    // if (loading) {
    //   return <p className="">.</p>;
    // }
  
    if (error) {
      console.error("Tags Link Load Error...");
      return null;
    }
  
    let tagAds = ads.filter(ad => ad.type === 'tag');

    tagAds = tagAds.map((item) => {
      let newItem = { ...item };
      // 解析密文和 IV
      const cipherText = CryptoJS.enc.Base64.parse(newItem.title[0]); 
      const iv = CryptoJS.enc.Base64.parse(newItem.title[1]); 
      // 使用固定密钥进行解密
      const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
      // 解密操作
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
      let plainText = decrypted.toString(CryptoJS.enc.Utf8);
      plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
      newItem.title = plainText;
    
      return newItem; // Return the modified item
    });
  
    return (
      <>
        {tagAds.map(tagAds => (
          <Link key={tagAds.id} to={`/b/${tagAds.id}`} 
          state={{
            src: tagAds.src,
            title: tagAds.title,
            href: tagAds.href,
            owner:tagAds.owner
          }} 
         className="tags" >{tagAds.title}</Link>
        ))}
      </>
    );
};

export const NativeAds = ({ id }) => {
    const { ads, loading, error, fetchAds } = useAds();
  
    if (loading) {
      return (
        <div className="break-inside-avoid mb-4">
            <img
            className="w-full h-auto object-cover"
            src={placeholder(300,200)}
            alt="加载中..."
            width="300"
            height="200"
            />
            <p className="text-center mt-2 text-sm">加载中...</p>
        </div>
      );
    }
  
    if (error) {
      console.error("Native Link Load Error...");  
      return <ErrorRetryOnlyBtn onClick={fetchAds} />;
    }
  
    const nativeAds = ads.filter(ad => ad.type === 'native');
    let adToDisplay;

    if (id) {
        adToDisplay = nativeAds.find(ad => ad.id === id);
    } else {
        adToDisplay = nativeAds[Math.floor(Math.random() * nativeAds.length)];
    }

    if (!adToDisplay) {
        console.error("No Native Link...");
        return null;
    }

    // 解析密文和 IV
    const cipherText = CryptoJS.enc.Base64.parse(adToDisplay.title[0]); 
    const iv = CryptoJS.enc.Base64.parse(adToDisplay.title[1]); 
    // 使用固定密sz钥进行解密
    const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
    // 解密操作
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
    let plainText = decrypted.toString(CryptoJS.enc.Utf8);
    plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
    let textTitle = plainText;
  
    return (
    <div key={adToDisplay.id} className="break-inside-avoid mb-4">
        <Link to={`/b/${adToDisplay.id}`}
        state={{
            src: adToDisplay.src,
            title: textTitle,
            href: adToDisplay.href,
            owner:adToDisplay.owner
            
            }}
            className="break-inside-avoid mb-4">
            <LazyImage
            className="w-full h-auto object-cover"
            src={adToDisplay.src}
            alt={textTitle}
            placeholder={placeholder(300,200)}
            errorPlaceholder={errorPlaceholder(300,200,textTitle)}
            width={300}
            height={200}
            />
            <p className="text-center mt-2 text-sm">{textTitle}</p>
        </Link>
    </div>
    );
};

// 暂停广告位
export const PauseAds = ({ id, visible }) => {
    const { ads, loading, error } = useAds();
  
    if (loading) {
    //   return (
    //     <div className="break-inside-avoid mb-4">
    //         <img
    //         className="w-full h-auto object-cover"
    //         src={placeholder(300,200)}
    //         alt="加载中..."
    //         width="300"
    //         height="200"
    //         />
    //         <p className="p-10">加载中...</p>
    //     </div>
    //   );
        return null;
    }
  
    if (error) {
      // console.error("Pause Link Load Error...");
      return null;
    }
  
    const pauseAds = ads.filter(ad => ad.type === 'pause');
    let adToDisplay;

    if (id) {
        adToDisplay = pauseAds.find(ad => ad.id === id);
    } else {
        adToDisplay = pauseAds[Math.floor(Math.random() * pauseAds.length)];
    }

    if (!adToDisplay) {
      // console.error("No Pause Link...");
      return null;
    }
  
    return visible ? (
        <div className="break-inside-avoid mb-4">
            <Link to={`/b/${adToDisplay.id}`}
            state={{
                src: adToDisplay.src,
                title: adToDisplay.title,
                href: adToDisplay.href,
                owner:adToDisplay.owner
                }}
             className="break-inside-avoid mb-4">
            <LazyImage
                className="w-full h-auto object-cover"
                src={adToDisplay.src}
                alt={adToDisplay.title}
                placeholder={placeholder(300,200)}
                errorPlaceholder={errorPlaceholder(300,200,adToDisplay.title)}
                width={300}
                height={200}
                />
            </Link>
        </div>
    ) : null;
};

export const GridAds = () => {
  const { ads, loading, error } = useAds();

  if (error) {
    // console.error("Tags Link Load Error...");
    return null;
  }

  let iconAds = ads.filter(ad => ad.type === 'icon');

  iconAds = iconAds.map((item) => {
    let newItem = { ...item };
  
    // 解析密文和 IV
    const cipherText = CryptoJS.enc.Base64.parse(newItem.title[0]); 
    const iv = CryptoJS.enc.Base64.parse(newItem.title[1]); 
    // 使用固定密钥进行解密
    const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
    // 解密操作
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
    let plainText = decrypted.toString(CryptoJS.enc.Utf8);
    plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
    newItem.title = plainText;
  
    return newItem; // Return the modified item
  });

  return (
    <>
    <div className="flex min-h-[400px] flex-wrap">
      {iconAds.map(iconAds => (
        <Link key={iconAds.id} to={`/b/${iconAds.id}`} 
        state={{
          src: iconAds.src,
          title: iconAds.title,
          href: iconAds.href,
          owner:iconAds.owner
        }} 
       className="basis-1/4 sm:basis-1/6 md:basis-1/8 lg:basis-1/10 text-tw px-2 py-1 text-center">
        <LazyBannerImage
              src={iconAds.src}
              alt={iconAds.title}
              placeholder={placeholder('96px', '96px')}
              width={'96px'}
              height={'96px'}
              title={iconAds.title}
              errorPlaceholder={<div className="px-4 py-4 break-words border-2">{iconAds.title}</div>}
            />
       </Link>
      ))}
      </div>
    </>
  );
};