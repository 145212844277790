import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import {BannerAds,GridAds } from '../components/AdsComponent';

const Tags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchTags = async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
       let tags = [
        "{'v1':'i/CYoW70s5GRbvr+4E/keg==','k1':'Ozlg9Q9ZQ+2chc1RreJSFQ=='}",
        "{'v1':'qNwKGccChV4/XDlLJQCiEA==','k1':'qUHEuJE5lYoKHxD+X6LqUw=='}",
        "{'v1':'NuJkVe9bI3riviTDcgcLwA==','k1':'e9ztkpCDK5PYrniDSv+yZw=='}",
        "{'v1':'yvQn3CfqLSspmnMhMLL9VA==','k1':'tI0q6npfTSLCrtpLXeTjuQ=='}",
        "{'v1':'Omru8Sw8MEeN864j48+5Cg==','k1':'2nHf+GAqD6d2ocW2Iv8Lpg=='}",
        "{'v1':'/zKCFWIi7fFpehRAQdijug==','k1':'4GEBCeiXPYGx4P+0Uq4DNg=='}",
        "{'v1':'SIYNsvw025ADj6B7pMUbmg==','k1':'eFffQUEPfTJs84YCK820hA=='}",
        "{'v1':'ZfXxkY9rzyr3CAIvET5F1w==','k1':'KuxqerKwD5eOcs49Qo8/6Q=='}",
        "{'v1':'koucE5Mq1kw5ZaCuXVyB2Q==','k1':'R5oguk7xjZ8vxIglw8Y5SA=='}",
        "{'v1':'8cz6KxaZMKlgNLGU8lzD7g==','k1':'SUDRzsI/u0ASUqbnE3CnPA=='}",
        "{'v1':'oEL867Wb2GmkGP/GrDUdDg==','k1':'RjHpSg05TAywif58CuJN6g=='}",
        "{'v1':'/aHUqmusf+9F8t+ZE1z0tA==','k1':'Qvtm/5kNGJ5NOMcZckMM/w=='}",
        "{'v1':'lAhIi6Fu3dKsHuerCAF6pw==','k1':'LWmbrhvf1VdyklZjMq8eOw=='}",
        "{'v1':'W9nTu873U49hGupSWDWdEA==','k1':'GFUmMt/JAApjCAlbdJohwg=='}",
        "{'v1':'XfAfuN+VRckV/RMFqzS5+A==','k1':'me7eo3F0O/wIyH+5NYuveA=='}",
        "{'v1':'Qjkabe9BJ7PbNK1whgS0YQ==','k1':'0M/bqmXw1y6902QAsFdptQ=='}",
        "{'v1':'gBuDUYCOHePWs/4AteTQ8A==','k1':'V77lyRMz5xbolDJzWsMHFg=='}",
        "{'v1':'dqLXUnQ23UnIap+Vm1295w==','k1':'mYBXqhCCoGLSf3dbnT+8KQ=='}",
        "{'v1':'W9GL9I3IPv5A27PQiWtWcQ==','k1':'dr+vOgYwwwOTbJdcS/A7QA=='}",
        "{'v1':'tlFWkCeISXXU2Sr6342pXg==','k1':'ZjwrK4mmh01ja6VCoCfriQ=='}",
        "{'v1':'gqjXNd5CldyioIW+OvObNA==','k1':'FluG4HBFy+yWusib5Al6pQ=='}",
        "{'v1':'+pSDPBOWuPk2NJX1wZN3/Q==','k1':'bj2ax5msj644HAHfFfNnUQ=='}",
        "{'v1':'92Kb1QxnpBkuLtzjzza0IA==','k1':'ErA13Y20e5pvbh1ogoaaKg=='}",
        "{'v1':'biNHsRzUWBCGponoSdm/uQ==','k1':'MiP6Yzqcjf1I44QD+ffdyQ=='}",
        "{'v1':'YNxOIkiX5SfsOUrSVfhLDA==','k1':'oDw8NGVYLXtDMC9TBk7/jw=='}",
        "{'v1':'ZcJBLU+yaWOkPdgTkVhQjQ==','k1':'SbGvlBizBgrdAE6f/0Ce3Q=='}",
        "{'v1':'NZHwAF4R6o/hcpttv42rDw==','k1':'LlGaCPfllSm1Bon3vijMNw=='}",
        "{'v1':'jj7+jC5mCoJ36+uaPbrsmg==','k1':'cd5Rq2frsVW1+0Y01ikTUQ=='}",
        "{'v1':'tyTUerZtehCWN0SjuY8+DQ==','k1':'QJzMoopqh7N3hQsq/eAeZA=='}",
        "{'v1':'NDJD26sUmfS+qvoIGdyHWw==','k1':'vVVs/bSkajkwdETKHxm6xg=='}",
        "{'v1':'dGR43cHO4VZ6ReRpzbTB2g==','k1':'zQMxZs2e21DCGmnqqeKiqw=='}",
        "{'v1':'FrbpTzh6jL/i2D9DabHK2w==','k1':'L+6R+IIDvoT56QrDIdUXSg=='}",
        "{'v1':'I8XTCx0aetBhv6EvJrX03w==','k1':'bLALABs4Na77yH+hJf1dqQ=='}",
        "{'v1':'GZeHgmSbaMbDz4Gu4tBGKg==','k1':'fFYNAxd2i90iGSYWjoW/1Q=='}",
        "{'v1':'dy1LXi8SgeB62btMTsh3cQ==','k1':'+d+sX+o+ZSMmAN2K6IcTsQ=='}",
        "{'v1':'shyBIagrrn+rLt6aiE22Fw==','k1':'zJ+MLdavIQ1ypNCurOzQuA=='}",
        "{'v1':'L/zgeSA5VHPfXKGvDWeqqA==','k1':'5EamT0i37S8jLA/oEbaJIA=='}",
        "{'v1':'N8pk5x6rtDI+/eHPbtDZUQ==','k1':'MZAxvRi9whyC9XfeXaIxuw=='}",
        "{'v1':'jI2+ssNb/AhL0YRmwLoAzQ==','k1':'UiDBEFM5vZzw5D7pTTEpIw=='}",
        "{'v1':'uSK6E+jQdbEFTv5NSuaY4g==','k1':'RKom7CrTQWLaMNqGKqpX4w=='}",
        "{'v1':'DScT9QJk0llVG0g4gEKwDQ==','k1':'QO6JEZVm4xPQ6YzPteWTow=='}",
        "{'v1':'kxa7DIygbIr3n2cPdU1tjg==','k1':'tOPIbY8PSXOQrjYPJDMlcg=='}",
        "{'v1':'HG+n9f8oVc9dVvwOO55/eQ==','k1':'EDon8n50G00DPPplKBdciA=='}",
        "{'v1':'fRr1nN4nO5xhgzDjsWSMkw==','k1':'dYGiVQ+w72H891oX/Qoz6w=='}",
        "{'v1':'6GvHHZaiQ10t2BAv7bHn8A==','k1':'HfCiA+d0YMzXL2C+ndX/lg=='}",
        "{'v1':'qusE/yWlXePFPkgTW1+VAA==','k1':'063lGRWkm/LZ0svvMoMF6w=='}",
        "{'v1':'sh096cBM48Wyx92dW3rLJA==','k1':'fTZDxq/RMPHyaJoPrbDC0w=='}",
        "{'v1':'GuJg4+qwK8LYNhlLzo9ing==','k1':'CdJtqE58rR9L0m2uRp+OLQ=='}",
        "{'v1':'fM9C85/CQ9NzbvIr1dNV3Q==','k1':'OMbYHGNhsZ8KrL70EjQboQ=='}",
        "{'v1':'ZZmDHsWga5XhNB4JT6A5Fg==','k1':'6AtLK/I7k0YZ7yLC4RUpfQ=='}",
        "{'v1':'2JvipJrdI9sGWKf7hzIfug==','k1':'SppfQcbN/HXGUoMEOXvR9w=='}",
        "{'v1':'Zhwh1RP+4yDij2rdb8RAwg==','k1':'3OX6uEY001FbVpe9378S5A=='}",
        "{'v1':'/Yrr4edJAjv46Y4Py5GFLA==','k1':'J7V+BYKu/91K9wRyyiqMbA=='}",
        "{'v1':'rUeuPYfjPVio7e6FKU1SKg==','k1':'YtmAu/pugLBPsjGxJSqo1Q=='}",
        "{'v1':'eRlaMkuyuljQnyaU7nyGsA==','k1':'lNXjkh7qA+jGvyqb9bhV6Q=='}",
        "{'v1':'k/REoGd00F6YuxalQadNkw==','k1':'1XN9aNX+oXMtbRep42hK4A=='}",
        "{'v1':'eYmKQok2gtTWXXwNIWt8yA==','k1':'AH7RSbVIkKttaxfRaOG8Ow=='}",
        "{'v1':'e7wQP3STC9WJCa067r6s0Q==','k1':'4hq5CaLjwq656heTzCNqAg=='}",
        "{'v1':'VVJJq+/a3ZCN8ifcyldxuw==','k1':'J16pIXyrnz/26eabNVz3ng=='}",
        "{'v1':'i5KHtHWgf+VSp5iOF7ts8w==','k1':'KDqxsChAhKSNtUuzNkzjxg=='}",
        "{'v1':'sW6WADk6ijWMd7BNHBSAAw==','k1':'mrKhSzdFHFnznCvphvYOlg=='}",
        "{'v1':'MuXPVRjj4rXpUaX6vwrvAQ==','k1':'vzHp5A1ezpIwLwj/bsr+Rw=='}",
        "{'v1':'IS6QEPZXlKB2UMiiAx1juw==','k1':'i/PVer9jzEhfs/kMtlCVlw=='}",
        "{'v1':'EgvJ2Ki6eTk+WoIwA5eQAw==','k1':'z5TaZqgON0/E0ML8oyy9Cw=='}",
        "{'v1':'R+cKnt5CX/u5ZJndVE3s6Q==','k1':'BrNEKPBiYSOLd8DRz/ZZlg=='}",
        "{'v1':'brjIXZ93kj3Dxyz01uwKyw==','k1':'lled7pHMBh4GYjTRPbCpDg=='}",
        "{'v1':'4ChrSl6GxIC2cngKV4BSEQ==','k1':'+AJL8sDBGdtmiAACt36/dA=='}",
        "{'v1':'anx+x5w3GYRfjQESiZpLpA==','k1':'l8sxU6SKyD/fA0HB20GBLA=='}",
        "{'v1':'DZcMPBZgXwUurjlEcOdq5A==','k1':'A5UXgyWXkuluqG+Zl5na+A=='}",
        "{'v1':'nyUbUSmVBQ0aT2XsiVObYg==','k1':'OebrZYADXOmK6/fUQfF7Sw=='}",
        "{'v1':'dLBEYbc7z/Mb5lTqMJzTBA==','k1':'cRAqO/j580JmU7B8UWnFtw=='}",
        "{'v1':'bxHPFv90spixUL6pzAiwuw==','k1':'G69UvaSRS1ApRbordRVN3A=='}",
        "{'v1':'e53jeQxeTMmR5Vht+eYH8g==','k1':'QQ9XS40DzBcwwHJ9Dz9Fow=='}",
        "{'v1':'qYb6uG4n6GJwcNzYETzVbA==','k1':'FoG8CSthw/xfRKAuWOF+SA=='}",
        "{'v1':'hv3xkcqrNLYGDRkIhqg8kg==','k1':'5rrbcNrrWVcRCfZe56Jxfw=='}",
        "{'v1':'eAjC8Bs+bHcSd/4ETmDdyA==','k1':'gAcnB0C8Xwcekt53ZD1f9A=='}",
        "{'v1':'zUCzv6Ngph1GntzMhr3/Eg==','k1':'qsyFkBC88CGVyOrgJsw1Ug=='}",
        "{'v1':'tkXK4Z8kRoMqgVWisp30yQ==','k1':'cYGpEjnGtck9nT3oAqcmgw=='}",
        "{'v1':'xhx+rCUpCSxDT8spD1fDlw==','k1':'/WTDklDkGmPBjTambz2q5g=='}",
        "{'v1':'I+L119rUDvr+189K38iEew==','k1':'FdcJ/+NvWoxbRdaEBD/Bzw=='}",
        "{'v1':'8+bUBcVg/uo7zBgD6mlU5A==','k1':'1pnWwf3mKI9TXDm7t/UGuA=='}",
        "{'v1':'pb0ELUYIqZilZTdVlkb1hQ==','k1':'f8999jM5OKoQYO4Eqmv/Kg=='}",
        "{'v1':'WtuCAwBf0u2z4ZYWZHbNNg==','k1':'8dO++fQFsM+YE/tXz5mK5g=='}",
        "{'v1':'uu4hc/ocsdcd8sWBCOGeuw==','k1':'Z7rEe55drOdkZ9fY72aCXA=='}",
        "{'v1':'/C8CbtEBeVd9+elvlA2JMA==','k1':'WTEEaNbCUuHaQb0nTmFerA=='}",
        "{'v1':'RtCqT4ra2J/mYzDJJoEdaw==','k1':'l/JTdaPnMtqEc3TFt09r8A=='}"
      ]
      
      tags = tags.map((item) => {
        const parsedItem = JSON.parse(item.replace(/'/g, '"')); 
        // 解析密文和 IV
        const cipherText = CryptoJS.enc.Base64.parse(parsedItem.v1); 
        const iv = CryptoJS.enc.Base64.parse(parsedItem.k1); 
        // 使用固定密钥进行解密
        const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
        // 解密操作
        const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
        let plainText = decrypted.toString(CryptoJS.enc.Utf8);
        plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
        parsedItem.title = plainText;
        return parsedItem; // Return the modified item
      });

      setTags(tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  if (loading) {
    return <LoadingText />;
  }

  if (error) {
    return <ErrorRetry title={'标签'} onClick={fetchTags} />;
  }

  return (
    <>
      <BannerAds />
      <GridAds/>
      <div className="title bg-tw text-tb p-2 md:p-4 text-left mb-4">所有标签列表</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-3 xs:gap-3 xs:text-xs s:text-sm">
        {tags.map((tag, index) => (
          <Link key={index} to={`/tags/${tag.title}/`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
            {tag.title}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Tags;
