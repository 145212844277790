import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaBanner = ({ href,name }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = href;
    }, 0);

    return () => clearTimeout(timer);
  }, [href,name]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>敬请稍候...{name}</title>
        <link rel="prefetch" href={href} />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaBanner;
