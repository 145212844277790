import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Pagination = ({ basePath, typename, currentPage, isLastPage }) => {
    const navigate = useNavigate();
    const [pageInput, setPageInput] = useState(currentPage);
    const [pages, setPages] = useState([]);
  
    useEffect(() => {
      const generatePages = () => {
        const startPage = currentPage;
        const endPage = isLastPage ? currentPage : currentPage + 5;
        const newPages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        setPages(newPages);
      };
  
      generatePages();
    }, [currentPage, isLastPage]);
  
    const handlePageChange = (page) => {
      const validPage = page < 1 ? 1 : page;
      navigate(`/${basePath}/${typename}/${validPage}`);
    };
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setPageInput(value);
    }
  };

  return (
    <div className="pagination max-w-4xl mx-auto space-y-4 text-center mb-4">
      {/* 第一行：页码 */}
      <div className="flex justify-center flex-wrap gap-2 text-sm">
        <button
          className="px-3 py-2 bg-tw text-tb hover:bg-tb hover:text-tw"
          onClick={() => handlePageChange(1)}
        >
          首页
        </button>
        {pages.map((page) => (
          <button
            key={page}
            className={`px-3 py-2 ${
              page === currentPage ? 'bg-sc text-tb' : 'bg-tw text-tb hover:bg-tb hover:text-tw'
            }`}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
      {/* 第二行：导航按钮 */}
      <div className="flex justify-between gap-2">
        <button
          className="w-1/2 px-3 py-2 bg-tw text-tb hover:bg-tb hover:text-tw"
          onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
        >
          上一页
        </button>
        <button
          className="w-1/2 px-3 py-2 bg-tw text-tb hover:bg-tb hover:text-tw"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={isLastPage}
        >
          下一页
        </button>
      </div>
      {/* 第三行：跳转功能 */}
      <div className="flex justify-center items-center">
        <div className="w-3/5 flex items-center">
          <label htmlFor="page-number" className="mr-2">
            跳到
          </label>
          <input
            id="page-number"
            type="number"
            className="w-3/5 py-1 border"
            value={pageInput}
            onChange={handleInputChange}
            style={{color:'black'}}
          />
          <span className="mx-3">页</span>
        </div>
        <button
          className="w-1/3 px-3 py-2 bg-tw text-tb hover:bg-tb hover:text-tw"
          onClick={() => handlePageChange(Number(pageInput))}
          disabled={!/^[1-9]\d*$/.test(pageInput)}
        >
          跳转
        </button>
      </div>
    </div>
  );
};

export default Pagination;
